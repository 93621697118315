<script lang="tsx">
import type { ColorSwatch } from '@sim-api-types/components'
import { ProductVariationPropertyAttributeModel } from '@simploshop-models/custom/product-variation-property-attribute.model'
import { LazyNuxtImg } from '#components'
import { defineComponentCoreUiSelector } from './CoreUiSelector.vue'
import type {
    CoreUiSelectorOptions,
    CoreUiSelectorProps,
    CoreUiSelectorSlots
} from './CoreUiSelector.vue'

export default function defineComponentCoreUiColorSwatchSelector<T, Colors extends string, Sizes extends string>(options?: ComponentOverrideOptions<CoreUiSelectorOptions, CoreUiSelectorProps<T, Colors, Sizes>, CoreUiSelectorSlots<T>>) {
    return defineComponentCoreUiSelector<any, Colors, Sizes>({
        options: options?.options,
        props: {
            ariaLabelKey: {
                default: '_core_simploshop.labels.color_selector',
            },
            valueGetter: {
                default: 'value',
            },
            // @ts-ignore
            labelGetter: {
                // @ts-ignore
                default: props => props?.values?.[0] instanceof ProductVariationPropertyAttributeModel ? (item: ProductVariationPropertyAttributeModel) => item.name : (item: ColorSwatch) => item.label,
            },
            // @ts-ignore
            useIndexAsValue: {
                // @ts-ignore
                default: () => item => item instanceof ProductVariationPropertyAttributeModel,
            },
            ...options?.props,
        },
        slots: {
            ...options?.slots,
            checkbox: (slotData) => {
                return (
                    <div class="sim-selector__background"
                        style={{
                            backgroundColor: slotData.item instanceof ProductVariationPropertyAttributeModel ? slotData.item.value ?? undefined : slotData.item?.value,
                        }}
                    >
                        {renderSlot(undefined, options?.slots?.checkbox, slotData)}

                        {slotData.item?.imageUrl && (
                            <LazyNuxtImg
                                src={slotData.item.imageUrl}
                                alt=""
                                class="no-drag absolute inset-0 size-full"
                                width={30}
                                height={30}
                                loading="lazy"
                            />
                        )}
                    </div>
                )
            },
        },
    })
}

</script>
